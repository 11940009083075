<template>
  <div>
    <base-header class="pb-6 bg-primary">
      <b-row class="align-items-center pt-4" ref="topSection">
        <b-col style="max-width: 1840px !important;">
          <base-button
          v-if="productName != 'Property'"
            @click="emailCustomer(formModel)"
            type="uBind"
            style="float:right;"
            class="mt-2 mt-sm-0 mb-4"
            ><i class="fas fa-envelope pr-3"></i>Send to Customer</base-button
          >

          <h6
            class="h1 text-white header d-inline-block mb-0 fw-500"
            ref="topSection"
          >
            {{ productName }}
          </h6>
        </b-col>
      </b-row>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="!quoteEmailed" style="max-width: 1800px !important;">
        <customer-form
          :productName="productName"
          @emailCustomer="emailCustomer"
          @nextStep="nextStep"
          :model="formModel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomerForm from "../../components/QuoteForm.vue";
import { GlobalStore } from "../../globalStore";
import { GlobalMethods } from "../../globalMethods";
import { ubindMethods } from "../../helpers/ubind";
import { emailMethods } from "../../helpers/email";
import { toasts } from "../../helpers/toasts";
import { appFunctions } from "../../helpers/appFunctions";
import { formattingMethods } from "../../helpers/formatting";

export default {
  name: "Step1",
  data() {
    return {
      quoteAccepted: true,
      quoteEmailed: false,
      productCode: '',
      formModel: {
        insuredName: "",
        insuredABN: "",
        businessDesc: "",
        contactFName: "",
        contactLName: "",
        contactEmail: "",
        contactPhone: "",
        insuredAddress: "",
        insuredStreet: "",
        insuredCity: "",
        insuredState: null,
        insuredPostcode: "",
        equipmentDescription: "",
        equipmentValue: null,
        equipmentCondition: "",
        operatingArea: "",
        usedOnPublicRoad: "",
        insurancePeriod: "12 Months",
        financier: "",
        Customer_policy_insurance_total_premium: null,
        Customer_policy_insurance_premium: null,
        Customer_policy_insurance_premium_gst: null,
        Customer_policy_insurance_premium_stamp_duty: null,
        Customer_policy_fee: null,
        Customer_policy_status: null
      }
    };
  },

  computed: {
    productName() {
      return formattingMethods.formatProductNameVIBS(this.$route.params.productName)
    }
  },

  methods: {
    checkProductCode() {
      const productCodes = {
        plantEquipment: "YIHQ-PE",
        motor: "YIHQ-MOTOR",
        otherAssets: "YIHQ-OTHER-ASSETS",
        property: "YIHQ-PROPERTY",
        jotformPELive: "YIHQ-PELIVE"
      }
      this.productCode = productCodes[this.$route.params.productName]
    },

    scrollToTop() {
      this.$smoothScroll({
        scrollTo: this.$refs.topSection,
        hash: "#topSection"
      });
    },
    async nextStep(formModel, callback) {
      let internalCustId = formModel.selectedCustomerId;

      const ubindOrgId =
        GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
          .ubind_organisation_id;

      if (!formModel.selectedCustomerId) {
        var newCustomer = {
          Customer_insured_name: formModel.insuredName,
          Customer_abn: formModel.insuredABN,
          Customer_contact_first_name: formModel.contactFName,
          Customer_contact_last_name: formModel.contactLName,
          Customer_contact_email: formModel.contactEmail,
          Customer_contact_number: formModel.contactPhone,
          Customer_contact_street: formModel.insuredStreet,
          Customer_contact_city: formModel.insuredCity,
          Customer_state: formModel.insuredState,
          Customer_postcode: formModel.insuredPostcode,
          Customer_country: "",
          Customer_business_description: formModel.businessDesc,
          Broker_org_id: GlobalStore.brokerObj.parentOrg.Broker_org_id,
          Broker_id: GlobalStore.brokerObj.Broker_id,
          Insurance_broker_id:
            GlobalStore.brokerObj.parentOrg.parentInsuranceBroker
              .insurance_broker_id
        };

        const customerResp = await appFunctions.crud_customer(
          "create",
          newCustomer,
          callback
        );

        if (!customerResp.data.Customer_id) {
          toasts.error(
            "Uh ohh... could not create " +
              formModel.insuredName +
              " please try again."
          );
          return;
        }

        internalCustId = customerResp.data.Customer_id;
      }

      this.createQuote(
        formModel,
        formModel.Ubind_customer_id,
        internalCustId,
        callback
      );

      // if (formModel.Ubind_customer_id) {
      //   this.createQuote(
      //     formModel,
      //     formModel.Ubind_customer_id,
      //     internalCustId,
      //     callback
      //   );
      // } else {
      //   const custObj = {
      //     tenantId: GlobalStore.ubindClientInfo.tenantId,
      //     organisationId: ubindOrgId,
      //     environment: GlobalStore.ubindClientInfo.environment,
      //     fullName: formModel.contactFName + " " + formModel.contactLName,
      //     preferredName: formModel.contactFName,
      //     email: formModel.contactEmail,

      //     homePhone: formModel.contactPhone,
      //     loginEmail: formModel.contactEmail,
      //     firstName: formModel.contactFName,
      //     lastName: formModel.contactLName,
      //     company: formModel.insuredName
      //   };

      //   ubindMethods.ubindCreateCustomer(custObj).then(resp => {
      //     var custid = resp.id;

      //     appFunctions
      //       .crud_customer("updateUbindCustomerId", {
      //         Customer_id: internalCustId,
      //         Ubind_customer_id: custid
      //       })
      //       .then(resp2 => {
      //         this.createQuote(
      //           formModel,
      //           custid,
      //           internalCustId,
      //           callback
      //         );
      //       });
      //   });
      // }
    },
    emailCustomer(formModel) {

      if (formModel.contactEmail != "") {
        this.nextStep(formModel, () => {
          const mostRecentPEPolicyId = sessionStorage.getItem(
            "mostRecentPEPolicyId"
          );
          appFunctions
            .crud_customerPolicy("getCombined", {
              policy_id: parseInt(mostRecentPEPolicyId)
            })
            .then(resp => {
              emailMethods.sendContinueQuoteEmail(
                GlobalStore.realmApp,
                resp.data,
                "VIBS",
                this.$route.params.productName
              );
            });
          toasts.success(`Email has been sent to ${formModel.contactEmail}`);

          this.$router.push("quoteEmailed");
        });
      } else {
        toasts.error(
          `The customer must have at least the email address populated. Please try again`
        );
      }
    },
    async createQuote(
      formModelData,
      customerId,
      internalCustomerID,
      callBack
    ) {
      let newQuoteRefNo = ''
      await appFunctions.crud_customerPolicy("getQuoteRef", {}).then(resp => {
        newQuoteRefNo = resp.data
      }).catch(error=> {
        toasts.error( "Generate quote ref error!");
        return;
      });
      const quoteId = GlobalMethods.generateQuoteId()
      let quoteObj = {
        Customer_policy_number: "",
        Product_code: this.productCode,
        Customer_policy_guid: GlobalMethods.uuidv4(),
        Customer_id: internalCustomerID,
        Customer_policy_status: "created",
        Ubind_customer_id: customerId,
        Jotform_quote_id: quoteId,
        Customer_policy_broker_referred_by: GlobalStore.brokerObj.Broker_first_name + " " + GlobalStore.brokerObj.Broker_last_name,
        Customer_policy_created_by: GlobalStore.brokerObj.Realm_user_id,
        Broker_org_id: GlobalStore.brokerObj.parentOrg.Broker_org_id,
        Insurance_broker_id:  GlobalStore.brokerObj.parentOrg.parentInsuranceBroker.insurance_broker_id,
        quoteRefNo: newQuoteRefNo
      };
      formModelData.Jotform_quote_id = quoteId
      formModelData.quoteRefNo = newQuoteRefNo

      try {
        const newQuoteId = await appFunctions.crud_customerPolicy("create", quoteObj)
        sessionStorage.setItem("mostRecentPEPolicyId", newQuoteId.data)
        if (callBack) {
          callBack()
        } else {
          this.$emit("goStep2", formModelData);
        }

      } catch(error) {
        toasts.error(
            "Create new quote error!"
        );
        return;
      }
    },
    initAddressSearch() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://api.addressfinder.io/assets/v3/widget.js"
      );
      script.setAttribute("async", true);
      script.onload = () => {
        this.widget = new window.AddressFinder.Widget(
          document.getElementById("addrs_1"),
          "NMY9RTVQ3UHADL76FJ8G",
          "AU",
          {
            address_params: {
              gnaf: 1
            }
          }
        );
        this.widget.on("result:select", (fullAddress, metaData) => {
          this.formModel.insuredAddress = metaData.address_line_1;
          this.formModel.insuredStreet = metaData.address_line_combined;
          this.formModel.insuredCity = metaData.locality_name;
          this.formModel.insuredState = metaData.state_territory;
          this.formModel.insuredPostcode = metaData.postcode;
        });
      };
      document.head.appendChild(script);
    }
  },
  components: {
    CustomerForm
  },
  watch: {
    productName() {
      this.checkProductCode()
    }
  },
  mounted() {
    this.checkProductCode()
    this.initAddressSearch();
    this.scrollToTop();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 45px;
  color: black;
}

.quote-emailed-screen {
  .card-header {
    border: none;
    padding-bottom: 0;
  }

  .uBind .card-body {
    padding: 0;
  }
}

.top-padding {
  padding-top: 0px !important;
}
.secondary-card-header {
  color: var(--uBind);
  font-size: 30px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
