<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-4 pt-lg-9"></div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- <img
        v-if="brokerObj"
        :src="getLogo"
        class="navbar-brand-img"
        alt="Sidebar logo"
        style="padding-bottom: 30px; width: 230px; padding-left: 15px;"
      /> -->
      <div>
        <base-header class="pb-6 bg-primary">
          <b-row class="align-items-center py-4">
            <b-col></b-col>
          </b-row>
        </base-header>
        <div class="px-0 px-sm-3 mt--6">
          <b-card no-body class="mb-4" style="max-width: 1800px !important;">
            <iframe
              v-if="canContinueQuote"
              id="JotFormIFrame-231378783234462"
              title="[Staging] P&E Protect Proposal Form"
              onload="window.parent.scrollTo(0,0)"
              allowtransparency="true"
              allowfullscreen="true"
              allow="geolocation; microphone; camera"
              :src="jotFormLink"
              frameborder="0"
              style="min-width:100%;max-width:100%;min-height:150vh;border:none;"
              scrolling="yes"
            >
            </iframe>
            <div v-else class="mainNotes"> 
              <img src='https://yihq-storage.sgp1.digitaloceanspaces.com/staging/favicon-1.jpg' class="imgBasic">
              <p class="mainBoldText">Sorry!</p>
              <p class="mainText"> You are not allowed to proceed any further with this action.</p>
            </div>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { quoteFormMethods } from "../../helpers/quoteForm";
import { GlobalStore } from "../../globalStore";
import { appFunctions } from "../../helpers/appFunctions";

export default {
  name: "ProductContinueQuote",
  data() {
    return {
      isReady: false,
      brokerObj: null,
      quoteObj: null,
      customerObj: null,
      userType: '',
      canContinueQuote: true
    };
  },
  computed: {
    getLogo() {
      return this.brokerObj.parentOrg.parentInsuranceBroker.insurance_broker_logo;
    },
    jotFormLink() {
      let result;
      const quoteObj = this.quoteObj  
      if (!quoteObj) return
      if ( this.userType === 'raa') {
        const jotformLinksforRAA = {
          plantEquipment: `${process.env.VUE_APP_PE_UPDATE_QUOTE_ID}?`,
          motor: `${process.env.VUE_APP_MOTOR_UPDATE_QUOTE_ID}?`,
          otherAssets: `${process.env.VUE_APP_OTHER_ASSETS_UPDATE_QUOTE_ID}?`,
          property: `${process.env.VUE_APP_PROPERTY_UPDATE_QUOTE_ID}?`,
          peLive: null
        }
        const raaParams = `businessName=${quoteObj.Jotform_data_obj.businessName}&insuredSubmissionId=${quoteObj.Jotform_data_obj.submissionID}&insuredAbn=${quoteObj.Jotform_data_obj.businessABN}&insuredContactName=${quoteObj.Jotform_data_obj.firstName + ' ' + quoteObj.Jotform_data_obj.lastName}&insuredEmail=${quoteObj.Jotform_data_obj.customerEmail}`
        result = "https://submit.jotform.com/" + jotformLinksforRAA[this.$route.params.productName] + raaParams
      } else {
        // broker already submit P&E proposal jorform
        if (quoteObj.Jotform_data_obj) {
          return result = `https://www.jotform.com/edit/${quoteObj.Jotform_data_obj.submissionID}`
        }
        const jotformLinksforCustomer = {
          plantEquipment: `${process.env.VUE_APP_PE_PROPOSAL_ID}?`,
          motor: `${process.env.VUE_APP_MOTOR_PROPOSAL_ID}?`,
          otherAssets: `${process.env.VUE_APP_OTHER_ASSETS_PROPOSAL_ID}?`,
          property: `${process.env.VUE_APP_PROPERTY_PROPOSAL_ID}?`,
          peLive: null
        }
        const yiCustomerParams = `quoteId=${quoteObj.Jotform_quote_id}&quoteRef=${quoteObj.Quote_Ref_No}&firstName=${this.customerObj.Customer_contact_first_name}&lastName=${this.customerObj.Customer_contact_last_name}
        &customerEmail=${this.customerObj.Customer_contact_email}&customerPhoneNumber=${this.customerObj.Customer_contact_number}&businessABN=${this.customerObj.Customer_abn}&businessName=${this.customerObj.Customer_insured_name}`
        result = "https://submit.jotform.com/" + jotformLinksforCustomer[this.$route.params.productName] + yiCustomerParams
      }
      return result
    },
  },
  methods: {
    checkStatus() {
      if (this.userType == 'yiCustomer') {
        if (this.quoteObj.Status && (this.quoteObj.Status == 'Offered' || this.quoteObj.Status == 'Accepted')) {
          this.canContinueQuote = false
        }
      }
      if (this.userType == 'raa') {
        if (this.quoteObj.Status == 'Accepted') {
          this.canContinueQuote = false
        }
      }
    }
  },

  async mounted() {      
    const quoteRes = await quoteFormMethods.getQuoteFromQuoteId(this.$route.params.id)
    this.userType = this.$route.params.userType
    this.brokerObj = quoteRes.brokerData;
    this.quoteObj = quoteRes.quoteData
    this.customerObj = quoteRes.customerData
    this.checkStatus()
  }
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
li,
p {
  color: black !important;
}
img {
  width: 350px;
  height: auto;
}

.imgBasic {
  display: block;
  width: 150px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.sideNotes {
  font-size: 0.9rem;
  margin-top: 28px;
}
.mainNotes {
  height: 500px;
  padding: 0.5rem;
  margin-top: 50px;
}

.mainText {
  font-size: 1.5rem;
  text-align: center;
}

.mainBoldText {
  color: #fb6340 !important;
  font-weight: bold;
  font-size: 3.5rem;
  text-align: center;
}
.financierMessage {
  color: red;
  font-style: italic;
}

@media (max-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }
}

@media (min-width: 767px) {
  .bordered-table-left {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding: 5px;
  }

  .bordered-table-right {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    padding: 5px;
  }

  .bordered-table-right .row {
    border-style: solid !important;
    border-width: 0px 0px 1px 0px !important;
    margin: 0px -5px 0px -5px;
    padding: 0px;
  }

  .bordered-table-right .row:last-child {
    border-style: none !important;
  }

  .my-radio > ::after {
    border: 1px solid var(--primary);
    border-radius: 100%;
  }

  .my-checkbox > ::after {
    border: 1px solid var(--primary);
    border-radius: 25%;
  }

  .my-invalid-feedback {
    color: #fb6340;
    width: 100%;
    font-size: 80%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .logo_image {
    width: 300px;
    image-rendering: -webkit-optimize-contrast;
  }
}

.ubind-product {
  margin: 0px 15px 0px 15px;
}
.container {
  padding-left: 0px;
  padding-right: 0px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
