<template>
  <div class="content">
    <Spinner :isLoading="isLoading" />
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <h6 class="h1 text-white d-inline-block mb-0 header fw-500">
            {{ productName }} Quotes
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- <route-bread-crumb></route-bread-crumb> -->
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button size="md" type="uBind" @click="newQuote()"
            ><i class="fas fa-plus pr-2"></i>New Quote</base-button
          >
        </b-col>
      </b-row>
    </base-header>
    <div class="mt--6 px-2 px-sm-4">
      <div>
        <div class="bg-white rounded-bottom change-color">
          <div>
            <b-col cols="12" class="pt-3 d-flex flex-wrap bg-white rounded-top">
              <el-select
                class="select-primary pagination-select mb-3 mr-3"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="mr-3">
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                  v-on:input="resetPagination()"
                >
                </base-input>
              </div>

              <div>
                <base-button
                  type="danger"
                  class="btn-refresh"
                  @click="refreshData()"
                >
                  <b-icon icon="arrow-repeat"></b-icon>
                </base-button>
              </div>
            </b-col>
            <el-table
              :data="queriedData"
              row-key="_id"
              :default-sort="{
                prop: 'Jotform_quote_created_date',
                order: 'descending'
              }"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
              v-loading="loading"
            >
              <el-table-column type="expand">
                <template #default="props">
                  <b-row class="px-5 expandedPolicy">
                    <b-col cols="6" md="7">
                      <b-row>
                        <b-col cols="5">
                          <p>Created Date:</p>
                          <p>Insured Contact Name:</p>
                          <p>Insured Email:</p>
                          <p>Number Of Vehicles:</p>
                          <p>Business Description:</p>
                          <p v-if="props.row.Status === 'Closed'">Close Reason:</p>
                        </b-col>
                        <b-col cols="7">
                          <p>
                            {{
                              formatDateTime(props.row.Jotform_quote_created_date)
                            }}
                          </p>
                          <p>
                            {{ props.row.Customer_contact_first_name }}
                            {{ props.row.Customer_contact_last_name }}
                          </p>
                          <p>{{ props.row.Customer_contact_email }}</p>
                          <p>
                            {{
                              getNumberOfVehicles(
                               () => props.row.Jotform_data_obj.insuredUnits
                              )
                            }}
                          </p>
                          <p>
                            {{
                              getSafe(() => props.row.Jotform_data_obj.businessOccupation.join(', '))
                            }}
                          </p>
                          <p v-if="props.row.Status === 'Closed'"> {{ props.row.closedReason }}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="1"></b-col>
                    <b-col cols="6" md="4">
                      <b-row class="pl-2" align-h="center">
                        <b-col cols="auto" md="12" class="mb-4">
                          <div v-if="!isBindQuote && props.row.Status === 'Offered'">
                            <base-button
                              @click.native="showQuoteScheduleList(props.row.offeredQuotes)"
                              class="edit mb-2"
                              type="danger"
                              size="sm"
                              icon
                              v-b-tooltip.hover
                              title="View proposal quote schedules"
                            >
                              <i class="fas fa-file btn-download"
                                ><span> Quote Schedules</span></i
                              >
                            </base-button>
                          </div>

                          <div v-for="quote in props.row.offeredQuotes" v-else>
                            <base-button
                              @click.native="showQuoteSchedule(quote, props.row.Jotform_quote_id)"
                              class="edit mb-2"
                              type="danger"
                              size="sm"
                              icon
                              v-b-tooltip.hover
                              title="View proposal quote schedule"
                            >
                            <!-- :disabled="(props.row.Status != 'Offered') && !quote.status" -->
                              <i class="fas fa-file btn-download"
                                ><span> {{ formatOfferedQuoteTitle(quote) }}</span></i
                              >
                            </base-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </el-table-column>
              <el-table-column
                min-width="95px"
                align="left"
                label="CONTINUE QUOTE"
              >
                <div
                  slot-scope="{ row }"
                  tabindex="0"
                  v-b-tooltip.hover
                  :title="showEditTooltip(row)"
                >
                  <base-button
                    @click.native="editQuote(row)"
                    class="edit"
                    type="danger"
                    size="sm"
                    icon
                    :disabled="showEditButton(row)"
                    v-if="row.Status !== 'Complete'"
                  >
                    <b-icon font-scale="2" icon="pencil-square"></b-icon>
                  </base-button>
                </div>
              </el-table-column>
              <el-table-column
                label="Status"
                min-width="170px"
                prop="Status"
                :filters="[
                  { text: 'Offered', value: 'Offered' },
                  { text: 'Requested', value: 'Requested' },
                  { text: 'Not Started', value: 'Not Started' }
                ]"
                :filter-method="filterStatus"
              >
                <!-- <template slot-scope="scope">
                  <el-tooltip
                    v-if="scope.row.status"
                    placement="top-start"
                    effect="dark"
                  >
                    <div slot="content" style="font-size: 15px">
                      It will be deleted after 15 days <br />
                      from Last Updated date if no update
                    </div>
                    <img
                      style="margin-bottom: 3px"
                      src="/img/icons/warning.png"
                    />
                  </el-tooltip>
                  <span
                    :style="
                      scope.row.status
                        ? 'margin-left: 5px'
                        : 'margin-left: 21px'
                    "
                    >{{ scope.row.status }}</span
                  >
                </template> -->
              </el-table-column>

              <el-table-column
                label="Quote Ref"
                min-width="130px"
                prop="Quote_Ref_No"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Last Updated"
                prop="Jotform_quote_last_updated_date"
                min-width="180px"
                sortable
                :formatter="
                  row => formatDateTime(row.Jotform_quote_last_updated_date)
                "
              >
              </el-table-column>

              <el-table-column
                label="Business Name"
                prop="Customer_insured_name"
                min-width="190px"
                sortable
              >
              </el-table-column>

              <!-- <el-table-column
                label="Total Payable"
                prop="Customer_policy_insurance_total_payable"
                min-width="150px"
                :formatter="row => formatPremium(row)"
                sortable
              >
              </el-table-column> -->

              <el-table-column
                label="Broker Name"
                min-width="180px"
                prop="Broker_Name"
                sortable
              >
              </el-table-column>

              <el-table-column
                label="Broker Organization"
                min-width="180px"
                prop="Broker_Org_Name"
                sortable
              >
              </el-table-column>

              <template #empty>
                <p>{{ loading ? "" : "No Data" }}</p>
              </template>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap py-3 px-5"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              :value="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @change="paginationChanged($event)"
            >
            </base-pagination>
          </div>
        </div>
      </div>
      <dialog-quote-schedule
          :isBindQuote="isBindQuote"
          v-on:modelPopupCancel="modelPopupCancel"
          v-on:handleCallAPI="handleCallAPI"
          :offeredQuote="offeredQuote"
          :offeredQuoteList="offeredQuoteList"
          :titleDialog="titleDialog"
          :quoteScheduleModal="quoteScheduleModal"
      />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Scrollbar } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../components/PaginatedTables/clientPaginationMixin";
import { GlobalStore } from "../../globalStore";
import { formattingMethods } from "../../helpers/formatting";
import { getNumberOfVehicles } from "../../helpers/common";
import { appFunctions } from "../../helpers/appFunctions";
import { toasts } from "../../helpers/toasts";
import DialogQuoteSchedule from "../components/DialogQuoteSchedule.vue"
import Spinner from "../dashboard/Spinner.vue";

export default {
  data() {
    return {
      tableData: [],
      selectedRows: [],
      quotesList: [],
      isLoading: false,
      loading: false,
      titleDialog: '',
      quoteScheduleModal: '',
      offeredQuote: {},
      offeredQuoteList: [],
      isBindQuote: false,
      productCode: ''
    };
  },
  computed: {
    productName() {
      return formattingMethods.formatProductNameVIBS(this.$route.params.productName)
    }
  },
  methods: {
    checkProductCode() {
      const productCodes = {
        plantEquipment: "YIHQ-PE",
        motor: "YIHQ-MOTOR",
        otherAssets: "YIHQ-OTHER-ASSETS",
        property: "YIHQ-PROPERTY",
        jotformPELive: "YIHQ-PELIVE"
      }
      this.productCode = productCodes[this.$route.params.productName]
    },

    modelPopupCancel() {
      this.quoteScheduleModal = false
    },

    showQuoteSchedule(quote, Jotform_quote_id) {
      this.title = null
      this.titleDialog = 'Quote Schedule'
      this.offeredQuote = quote
      this.offeredQuote.Jotform_quote_id = Jotform_quote_id
      this.quoteScheduleModal = true
    },

    showQuoteScheduleList(offeredQuoteList) {
      this.title = null
      this.titleDialog = 'Quote Schedules'
      this.offeredQuoteList = offeredQuoteList
      this.quoteScheduleModal = true
    },

    async handleCallAPI() {
      this.isLoading = true
      await appFunctions.crud_customerPolicy("acceptedQuote", {
          quoteAccepted: this.offeredQuote,
          productName: this.$route.params.productName,
          //documents: documents
        })
        .then(resp => {
          this.fetchData()
          this.quoteScheduleModal = false
          this.isLoading = false
        })
        .catch((error) => {
          this.quoteScheduleModal = false
          toasts.error("Update quote error!")
        })
        .finally(() => {
          this.isLoading = false
        });
    },

    formatData(offeredQuotes) {
      offeredQuotes.basePremium = offeredQuotes.basePremium ? "$" + formattingMethods.numberWithCommas(offeredQuotes.basePremium) : ""
      offeredQuotes.ESL = offeredQuotes.ESL ? "$" + formattingMethods.numberWithCommas(offeredQuotes.ESL) : ""
      offeredQuotes.policyFee = offeredQuotes.policyFee ? "$" + formattingMethods.numberWithCommas(offeredQuotes.policyFee) : ""
      offeredQuotes.totalPayable = offeredQuotes.totalPayable ? "$" + formattingMethods.numberWithCommas(offeredQuotes.totalPayable) : ""
      return offeredQuotes
    },

    showEditTooltip(row) {
      return row.Status == "Offered"
        ? "Offered Quotes cannot be edited"
        : "Continue Quote and/or Edit Quote";
    },

    showEditButton(row) {
      return row.Status == "Offered" || row.Status == "Closed" ? true : false;
    },

    editQuote(rowData) {
      const hasSubmissionId = this.getSafe(() => rowData.Jotform_data_obj.submissionID)
      if (hasSubmissionId !== 'N/A') {
        sessionStorage.setItem("jotformQuoteID", JSON.stringify(rowData));
        this.$router.push(`/products/${this.$route.params.productName}/editquote/` + hasSubmissionId);
      }

      if (hasSubmissionId == 'N/A') {
        sessionStorage.setItem("jotformQuoteID", JSON.stringify(rowData));
        this.$router.push(`/products/${this.$route.params.productName}/editquote/` + "NA");
      }
    },

    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    },

    newQuote() {
      this.$router.push("createQuote");
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
    },

    formatDate(dateVal) {
      return formattingMethods.formatDate(dateVal);
    },

    formatDateTime(dateVal) {
      return dateVal === "N/A"
        ? dateVal
        : formattingMethods.formatDateTime(dateVal);
    },

    // async downloadQuoteSchedule(row, type) {
    //   const pdf = await appFunctions.getFileAttachment(
    //     "getPolicyFileAttachment",
    //     { policy_id: row.Customer_policy_id, type }
    //   );
    //   if (!pdf) {
    //     toasts.error(
    //       "Documents are currently being generated - try again shortly"
    //     );
    //   } else {
    //     // download
    //     this.downloadPDFFromBase64(pdf.fileContent, pdf.filename);
    //   }
    // },

    // downloadPDFFromBase64(pdfData, fileName) {
    //   const linkSource = `data:application/pdf;base64,${pdfData}`;
    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = linkSource;
    //   downloadLink.download = fileName;
    //   downloadLink.click();
    // },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    /**
     * Used by the filter dropdown to filter the table by status
     * @param {string} value - the value of the filter dropdown
     * @param {object} row - the row of the table
     * @returns {boolean} - true if the row should be shown, false if not
     */
    filterStatus(value, row) {
      return row.status === value;
    },

    /**
     * Format the premium amount to include commas and dollar symbol.
     * @param {Object} row - row data.
     * @returns {String} - formatted amount.
     */
    formatPremium(row) {
      const totalPayable =
        row.Customer_policy_insurance_total_payable &&
        row.Customer_policy_insurance_total_payable.toString().replace("$", "");
      return "$" + formattingMethods.numberWithCommas(totalPayable);
    },

    formatOfferedQuoteTitle(quote) {
      const totalPayable = "$" + formattingMethods.numberWithCommas(quote.totalPayable)
      return `Quote Schedule - Total Payable: ${totalPayable}`
    },

    async fetchData() {
      this.loading = true;
      await appFunctions
        .crud_customerPolicy("listByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
          type: "quote",
          productCode: this.productCode
        })
        .then(resp => {
          var tdata = resp.data.data;
          const isPartner = resp.data.isPartner
          // only special partner can bind quotes
          if (isPartner) {
            this.isBindQuote = true
          }

          tdata.forEach(q => {
            // Formatting number values.
            q.status = false;

            if (!!!q.Status) {
              q.Status = "Not Complete";
              // q.Ubind_refNo = "N/A";
            }

            q.Broker_Name = q.createdByName;
            q.Status = this.capitalizeFirstLetter(q.Status);
          });

          tdata.sort(function(a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.Jotform_quote_created_date) -
              new Date(a.Jotform_quote_created_date)
            );
          });

          this.tableData = tdata;

          this.initFuse([
            "Customer_insured_name",
            "Quote_Ref_No",
            "Status",
            "Broker_Name",
            "Broker_Org_Name"
          ]);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refreshData() {
      this.resetPagination();
      this.searchQuery = "";
      this.tableData = [];
      this.fetchData();
    },
  },
  watch: {
    productName() {
      this.checkProductCode()
      this.resetPagination();
      this.searchQuery = "";
      this.tableData = [];
      this.fetchData()
    }
  },
  mounted() {
    this.checkProductCode()
    this.fetchData();
  },
  mixins: [clientPaginationMixin],
  components: {
    DialogQuoteSchedule,
    BasePagination,
    RouteBreadCrumb,
    Spinner,
    [Scrollbar.name]: Scrollbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  }
};
</script>
<style scoped>
.container {
  max-width: 1800px;
  margin: 0px 30px 0px 30px;
}
.expandedPolicy p {
  font-size: 0.9rem;
  color: #000000;
}
.btn-download {
  font-size: 0.9rem;
  margin: 5px;
  min-width: 10em;
}

.btn-download span {
  font-family: sans-serif;
}

.btn-refresh {
  padding: 0.64rem 0.85rem;
  font-size: 1rem;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: none;
}
</style>

<style>
.el-table .cell {
  box-sizing: border-box;
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}
</style>
